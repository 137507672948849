@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&family=Caveat:wght@400;500;600;700&display=swap');

.main-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}
.blur-background {
  height: 100%;
  width: 100%;
}
button.gradient-link {
  border: unset;
}
.gradient-link {
  padding: 27px 0;
  display: block;
  position: relative;
  width: 453px;
  height: 80px;
  font-size: 22px;
  line-height: 27px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  border-radius: 100px;

  @media (max-width: 767px) {
    padding: 17px 0;
    width: 296px;
    height: 52px;
    font-size: 16px;
    line-height: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    background: no-repeat url("https://soultime.s3-eu-west-1.amazonaws.com/assets/icon_arrow_right_30_56.svg");
    background-size: cover;
    height: 30px;
    width: 56px;
    top: 25px;
    right: 45px;
    @include transition(0.4s);

    @media (max-width: 767px) {
      height: 20px;
      width: 37px;
      top: 17px;
      right: 22px;
    }
  }
  &:hover {
    color: #fff;
    &:after {
      right: 35px;

      @media (max-width: 767px) {
        right: 17px;
      }
    }
  }
}
.gradient-link-1 {
  margin: 0 0 148px;
  background: linear-gradient(92.67deg, #9E6BF9 12.54%, #888AFC 29.07%, #799FFF 44.64%, #79CDFE 92.75%);

  @media (max-width: 767px) {
    margin: 0 0 48px;
  }
}
.gradient-link-2 {
  margin-top: 64px;
  background: linear-gradient(333.08deg, #6CADFF 8.13%, #6A69EF 91.3%, #6A69EF 91.3%);

  @media (max-width: 767px) {
    margin-top: 40px;
  }
}
.gradient-link-3 {
  margin-top: 64px;
  background: linear-gradient(29.71deg, #8A89F3 14.35%, #D760DA 90.75%);

  @media (max-width: 767px) {
    margin-top: 40px;
  }
}
.gradient-link-4 {
  margin-top: 48px;
  background: linear-gradient(16.27deg, #B44AC0 33.01%, #FFA1D4 89.18%);

  @media (max-width: 767px) {
    margin-top: 32px;
  }
}
#checkup-subscribe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  min-width: 832px;
  background-color: #312F3B;
  box-shadow: 13px 20px 28px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  @media (max-width: 991px) {
    min-width: 740px;
  }
  @media (max-width: 767px) {
    min-width: 500px;
  }
  @media (max-width: 575px) {
    width: 343px;
    min-width: 343px;
    top: 7px;
    transform: translate(-50%, 0);
  }
  @media (max-width: 374px) {
    width: 310px;
    min-width: 310px;
  }
  .checkup-subscribe-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px 0 54px;
    background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/checkup_subscription_d.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 16px 16px 0 0;

    @media (max-width: 575px) {
      padding: 11px 0 100px;
      background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/checkup_subscription_m.jpg");
    }
    .offer-short {
      margin: 0;
      padding: 0 12px;
      background-color: rgba(26, 26, 26, 0.25);
      border-radius: 55px;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;

      @media (max-width: 575px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media (max-width: 374px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .offer {
      margin: 22px 0 0;
      font-weight: bold;
      font-size: 46px;
      line-height: 35px;
      text-transform: uppercase;

      @media (max-width: 575px) {
        margin: 0;
        font-size: 32px;
        line-height: 35px;
      }
    }
    .offer-description {
      margin: 35px 0 0;
      max-width: 190px;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;

      @media (max-width: 575px) {
        margin: 8px 0 0;
        font-size: 14px;
        line-height: 23px;
        max-width: unset;
      }
    }
  }
  .checkup-subscribe-description {
    display: flex;
    padding: 28px 90px 0;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 22px;

    @media (max-width: 767px) {
      padding: 28px 20px 0;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      padding: 0 6px 0;
      font-size: 14px;
      line-height: 19px;
    }
    ul {
      flex: 1;
      margin: 0;
      padding-left: 25px;

      @media (max-width: 575px) {
        flex-direction: column;
        padding-left: 0;
      }
      li {
        position: relative;
        list-style: none;
        padding-left: 23px;

        @media (max-width: 575px) {
          margin-top: 8px;
        }
        .check {
          display: block;
          position: absolute;
          top: 6px;
          left: 0;
          height: 11px;
          width: 11px;
          border-radius: 50%;

          i {
            display: block;
            height: 11px;
            width: 11px;
            background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/check_true.svg");
            background-repeat: no-repeat;
          }
        }
        .first {
          background: linear-gradient(191.22deg, #6AEC77 -39.72%, #5C50E8 74.36%);
        }
        .second {
          background: linear-gradient(289.18deg, #6CADFF 8.13%, #6A69EF 91.3%, #6A69EF 91.3%, #5D5AE1 91.3%);
        }
        .third {
          background: linear-gradient(58.72deg, #6CADFF 14.33%, #A568E9 87.46%);
        }
        .fourth {
          background: linear-gradient(72.8deg, #8786F6 14.35%, #D760DA 90.75%);
        }
        .fifth {
          background: linear-gradient(46.4deg, #9B6FD5 24.44%, #758BFF 91.33%);
        }
      }
      li:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
  .checkup-subscribe-note {
    margin: 15px 0 0;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
  }
  .checkup-subscribe-footer {
    margin-top: 15px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 575px) {
      margin-top: 8px;
    }
    p {
      font-size: 20px;
      line-height: 24px;

      @media (max-width: 575px) {
        margin: 0;
      }
    }
    .continue-button {
      margin: 0;
    }
    .old-price {
      font-family: "Nunito Sans", sans-serif;
      font-weight: bold;
      color: #FF658A;

      @media (max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
      .price {
        position: relative;
        display: inline-block;
        padding: 0 2px;
        color: #FF658A;
      }
      .decoration {
        position: absolute;
        top: 12px;
        left: -1px;
        border-bottom: 2px solid #FF658A;
        width: 100%;
      }
    }
    .discount {
      margin: 0;
      color: #74CDFF;
      font-weight: 600;
      letter-spacing: 1px;

      @media (max-width: 575px) {
        font-size: 15px;
        line-height: 24px;
      }
    }
    .price-description {
      font-size: 14px;
      line-height: 18px;
      text-transform: initial;

      @media (max-width: 575px) {
        margin-bottom: 8px;
        font-size: 11px;
        line-height: 11px;
      }
    }
    .gradient-link {
      margin: 0;
      padding: 10px 0;
      height: 52px;
      width: 346px;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 575px) {
        padding: 7px 0;
        height: 44px;
        width: 278px;
      }
      &:after {
        height: 20px;
        width: 37px;
        top: 17px;
        right: 21px;

        @media (max-width: 575px) {
          top: 13px;
        }
      }
      &:hover {
        &:after {
          right: 11px;
        }
      }
    }
    .cancel {
      margin: 20px 0 32px;
      //@media (max-width: 575px) {
      //}
      button {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.04em;
        background-color: transparent;
        border: none;
      }
    }
  }
}

#checkup-subscribe-confirmation {
  padding: 30px;

  @media (max-width: 374px) {
    padding: 5px;
  }
  .modal-dialog {
    width: max-content;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 575px) {
      width: 310px;
      margin: 0 auto;
    }
    .modal-content {
      padding: 32px;
      border-radius: 20px;
      background-color: #E9EEF4;

      @media (max-width: 575px) {
        padding: 32px 11px;
      }
      .modal-body {
        padding: 0;

        p {
          margin: 0;
          font-size: 20px;
          font-weight: 300;
          line-height: 30px;
          color: #615E73;
          text-align: center;

          @media (max-width: 575px) {
            font-size: 18px;
          }
        }
        .discount {
          font-weight: bold;
          color: #79A4FF;
          text-transform: uppercase;
        }
        .discount-subscription {
          margin-top: 19px;

          @media (max-width: 575px) {
            margin-top: 11px;
          }
        }
        .percent {
          margin-top: 22px;
          font-size: 49px;
          line-height: 100%;

          @media (max-width: 575px) {
            margin-top: 18px;
          }
        }
        .discount-description {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 20px;

          @media (max-width: 575px) {
            font-size: 19px;
          }
        }
      }
      .confirmation-modal-footer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 32px;

        @media (max-width: 575px) {
          flex-direction: column;
          flex-flow: wrap-reverse;
        }
        button {
          display: block;
          margin: 0;
          padding: 11px 0;
          height: 54px;
          width: 173px;
          font-size: 16px;
          font-weight: bold;
          line-height: 34px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          cursor: pointer;

          @media (max-width: 575px) {
            height: 44px;
            padding: 6px 0;
          }
          &:first-child {
            margin-right: 12px;
            font-weight: 600;

            @media (max-width: 575px) {
              margin: 0;
            }
          }
          &:last-child {
            @media (max-width: 575px) {
              margin-bottom: 16px;
            }
          }
          @media (max-width: 575px) {
            width: 100%;
          }
        }
        .btn-gradient {
          position: relative;
          background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/button-gradient-54x173.svg");
          background-repeat: no-repeat;
          color: #8685A5;

          @media (max-width: 575px) {
            background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/button-gradient-44x286.svg");
          }
          &:before {
            @include transition(0.4s);
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            content: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/button-gradient-54x173_bold.svg");
          }
          &:hover:before {
            opacity: 1;
          }
        }
        .gradient-link {
          position: relative;
          z-index: 1;
          color: #fff;

          &:after {
            @include transition(0.4s);
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            border-radius: 27px;
            background: linear-gradient(91.51deg, #9E6BF9 12.54%, #888AFC 49.3%, #799FFF 92.75%);
          }
          &:hover:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

#check-up-intro {
  .wrapper {
    padding-bottom: 192px;

    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }
  .background-mountain {
    height: 100%;
  }
  .container {
    padding: 0 22px;
  }
  .outer-link {
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .checkup-intro {
    .grid {
      display: flex;
      flex-direction: column;
      justify-content: start;

      .logo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 60px;

        @media (max-width: 575px) {
          padding-top: 26px;
        }
        .logo {
          height: 114px;
          width: 163px;
          background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/logo_vertical_114x163.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;

          @media (max-width: 575px) {
            height: 85px;
            width: 122px;
          }
        }
      }
      .logo-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 35px;

        @media (max-width: 767px) {
          margin-top: 28px;
        }
        p {
          margin: 0;
          text-align: center;
        }
        div {
          text-align: center;
        }
        hr {
          width: 100px;
          margin: 10px 0;
          border: 1px solid #fff;
          opacity: 0.7;
        }
        .download-description {
          font-family: "Caveat", cursive;
          //font-weight: 600;
          //letter-spacing: -1px;
          font-size: 96px;
          line-height: 80px;

          @media (max-width: 767px) {
            font-size: 36px;
            line-height: 33px;
          }
        }
        .login-description {
          margin: 25px 0 75px;
          max-width: 440px;
          font-family: "Nunito Sans", sans-serif;
          font-size: 26px;
          line-height: 36px;

          @media (max-width: 767px) {
            margin: 28px 0 40px;
            font-size: 18px;
            line-height: 30px;
          }
        }
        .personality-test {
          font-size: 24px;
          line-height: 36px;
          font-family: "Nunito Sans", sans-serif;

          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 30px;
          }
        }
        .get-results {
          margin-bottom: 52px;
          font-size: 24px;
          line-height: 36px;
          font-family: "Nunito Sans", sans-serif;

          @media (max-width: 767px) {
            margin-bottom: 40px;
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }

  .counts-container {
    text-align: center;
    margin-top: 64px;
    padding: 48px 8px;
    background: radial-gradient(31.67% 82.58% at 50% 50%, rgba(87, 184, 255, 0.104) 0%, rgba(87, 184, 255, 0.104) 0.01%, rgba(255, 255, 255, 0) 100%, rgba(87, 184, 255, 0) 100%), rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    @media (max-width: 575px) {
      padding: 34px 8px;
    }

    p {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 36px;
      font-family: "Nunito Sans", sans-serif;

      @media (max-width: 575px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .join {
      margin: 0;
      text-transform: uppercase;
      font-family: "Montserrat", Arial, sans-serif;
      font-weight: bold;

      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .count {
      margin: 0;
      font-family: "Caveat", cursive;
      //font-weight: bold;
      font-size: 72px;
      line-height: 90px;
      letter-spacing: 0.07em;
      text-transform: uppercase;

      @media (max-width: 575px) {
        font-size: 56px;
        line-height: 56px;
      }
    }
    .count-header {
      margin-bottom: 52px;
      font-family: "Montserrat", Arial, sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      text-transform: uppercase;
      //letter-spacing: 1px;

      @media (max-width: 575px) {
        margin-bottom: 28px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }
    }
    a {
      color: #74CDFF;
      text-decoration: none;

      &:hover {
        border-bottom: solid 1px #74CDFF;
      }
    }
  }

  .feedback-container {
    background-color: rgba(0,0,0,0.3);
    border-radius: 8px;
    margin-top: 24px;
    padding: 48px;

    @media (max-width: 765px) {
      padding: 24px 12px;
    }
    .item {
      padding: 0 80px;

      @media (max-width: 991px) {
        padding: 0 25px;
      }

      @media (max-width: 765px) {
        padding: 0;
      }
      &.border-gradient {
        border-left: 1px solid #5D5AE1;

        @media (max-width: 991px) {
          border-left: unset;
          border-top: 1px solid #5D5AE1;
        }
        @media (max-width: 765px) {
          margin-top: 48px;
          padding-top: 48px;
        }
      }
      p {
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        line-height: 24px;

        @media (max-width: 765px) {
          margin: 0;
        }
      }
      .title {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 22px;
        line-height: 33px;
        text-transform: uppercase;
      }
      .rating {
        margin-bottom: 20px;

        .star {
          display: inline-block;
          height: 20px;
          width: 20px;
          background: no-repeat url("https://soultime.s3-eu-west-1.amazonaws.com/assets/star_yellow_20x20.svg");
          background-size: cover;

          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .img-block {
    margin-top: 112px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px) {
      margin-top: 0;
    }
    .img-title {
      margin: 0;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;

      @media (max-width: 767px) {
        margin-top: 56px;
        font-size: 22px;
        line-height: 33px;
      }
    }
    .img-container {
      margin-top: 48px;
      height: 382px;
      background: no-repeat center center url("https://soultime.s3-eu-west-1.amazonaws.com/assets/intro_checkup_full_image.png");
      background-size: cover !important;

      @media (max-width: 575px) {
        display: none;
      }
    }
    .checkup-slider {
      display: none;
      width: 100%;
      margin-top: 28px;

      @media (max-width: 575px) {
        display: block;
      }
      .slick-next, .slick-prev {
        top: 25%;
      }
      .checkup-item {
        padding: 0 15px;
        border-radius: 12px;

        img {
          width: 100%;
          border-radius: 12px;
        }
      }
      .slick-dots {
        bottom: -35px;
        li {
          button:before {
            font-size: 30px;
            color: #fff;
            opacity: 0.5;
          }
        }
        .slick-active {
          button:before {
            font-size: 40px;
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
    .img-desc {
      margin: 32px auto 0;
      max-width: 658px;
      font-family: "Nunito Sans", sans-serif;
      font-size: 18px;
      line-height: 28px;
      color: #E9EEF4;
      text-align: center;
    }
  }

  .emotions-container {
    margin-top: 152px;

    @media (max-width: 991px) {
      margin-top: 72px;
    }
    p {
      margin: 0;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;

      @media (max-width: 991px) {
        text-align: center;
      }
    }
    .emotions-block {
      padding-top: 55px;

      @media (max-width: 991px) {
        padding: 0;
      }

      .emotions-desc-1 {
        margin-top: 48px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #E9EEF4;

        @media (max-width: 991px) {
          margin-top: 27px;
        }
      }
      .emotions-desc-2 {
        margin-top: 48px;
        font-family: "Nunito Sans", sans-serif;
        font-size: 24px;
        line-height: 36px;

        @media (max-width: 991px) {
          margin-top: 27px;
        }
      }
      .outer-link {
        justify-content: left;

        @media (max-width: 991px) {
          justify-content: center;
        }
        .gradient-link-4 {
          @media (max-width: 767px) {
            margin-top: 32px;
          }
        }
      }
    }
    .emotions-img {
      margin: 0 auto;
      height: 557px;
      background: no-repeat center center url("https://soultime.s3-eu-west-1.amazonaws.com/assets/checkup_emotions_mountain_557x410.svg");
      background-size: contain;

      @media (max-width: 991px) {
        margin-top: 32px;
      }
      @media (max-width: 767px) {
        height: 402px;
      }
    }
  }
}

#check-up {
  padding-top: 60px;
  @media (max-width: 767px) {
    padding-top: 32px;
  }
  .main {
    padding-top: 92px;
    max-width: 810px;
    margin: 0 auto;

    @media (max-width: 767px) {
      padding-top: 34px;
    }
    .header {
      margin-bottom: 152px;

      @media (max-width: 767px) {
        margin-bottom: 80px;
      }
      h1 {
        margin: 0;
        font-family: 'Caveat Brush', cursive;
        font-style: normal;
        text-align: center;
        font-size: 44px;
        line-height: 48px;
        letter-spacing: 1px;

        @media (max-width: 767px) {
          font-size: 36px;
          line-height: 32px;
        }
      }
      .icons {
        display: flex;
        flex-direction: row;
        margin-top: 50px;

        @media (max-width: 767px) {
          align-items: center;
          flex-direction: column;
          margin-top: 0;
        }
        .icon-block {
          margin: 0 10px;
          width: 270px;
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 767px) {
            margin: 24px 0 0;
          }
          .image {
            height: 100px;
            width: 100px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            &.image-clock {
              background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/icon_checkup_clock_120x120.svg");
            }
            &.image-heart {
              background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/icon_checkup_heart_120x120.svg");
            }
            &.image-question {
              background-image: url("https://soultime.s3-eu-west-1.amazonaws.com/assets/icon_checkup_question_120x120.svg");
            }
          }
          p {
            margin: 12px 0 0;
            text-align: center;
            font-family: "Caveat", cursive;
            font-style: normal;
            font-size: 28px;
            line-height: 28px;
          }
        }
      }
    }
    .question-container {
      padding: 0 64px;

      @media (max-width: 575px) {
        padding: 0;
      }
      .progress {
        height: 50px;
        padding: 0 22px 0 76px;
        position: relative;
        display: flex;
        align-items: center;
        background-color: rgba(255,255,255,0.2);
        border-radius: 17px;

        @media (max-width: 767px) {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          border-radius: 0 0 4px 4px;
          background-color: #403E4D;
          //opacity: 1;
          z-index: 1;
        }
        .percent {
          position: absolute;
          left: 30px;
          top: 17px;
          font-family: "Nunito Sans", sans-serif;

          &:after {
            content: '%';
          }
        }
        .progress-container {
          height: 6px;
          width: 100%;
          background-color: rgba(35,39,53,0.5);
          border-radius: 2px;
        }
        .progress-bar {
          height: 100%;
          width: 0;
          background-color: #fff;
          border-radius: 2px;
        }
      }
      .question-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 80px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        @include transition(0.2s);

        @media (max-width: 767px) {
          padding-bottom: 18px;
        }
        &:first-child {
          margin-top: 52px;
          @media (max-width: 767px) {
            margin-top: 0;
          }
        }
        &:not(:first-child) {
          margin-top: 68px;
          @media (max-width: 767px) {
            margin-top: 34px;
          }
        }
        &.visible {
          opacity: 1;
        }
        &.hide {
          opacity: 0;
        }
        .question {
          max-width: 422px;
          text-align: center;
          font-family: "Nunito Sans", sans-serif ;
          font-size: 18px;
          line-height: 28px;

          .question-stub {
            padding-right: 5px;
            display: inline-block;
            min-height: 2px;
            min-width: 43px;
            border-bottom: 1px solid #74CDFF;
            font-family: "Caveat", cursive;
            font-size: 27px;
            line-height: 27px;
          }
        }
      }
      .answer-block {
        margin-top: 28px;
        padding: 0 98px;
        position: relative;

        @media (max-width: 767px) {
          margin-top: 24px;
          padding: 0 14px;
        }
        .answer {
          display: flex;
          flex-direction: row;

          .option {
            height: 45px;
            width: 45px;
            background: no-repeat center url("https://soultime.s3-eu-west-1.amazonaws.com/assets/icon_radio_empty_44x44.svg");
            background-size: cover;
            cursor: pointer;
            @include transition(0.3s);

            @media (max-width: 767px) {
              height: 32px;
              width: 32px;
            }
            .inner {
              height: 45px;
              width: 45px;
              opacity: 0;
              background: no-repeat center url("https://soultime.s3-eu-west-1.amazonaws.com/assets/icon_radio_gradient_44x44.svg");
              background-size: cover;
              @include transition(0.3s);

              @media (max-width: 767px) {
                height: 32px;
                width: 32px;
              }
            }
            &:not(:first-child) {
              margin-left: 20px;

              @media (max-width: 767px) {
                margin-left: 11px;
              }
            }
            &:hover {
              background: no-repeat center url("https://soultime.s3-eu-west-1.amazonaws.com/assets/icon_radio_empty_hover_44x44.svg");
              background-size: cover;
              @include transition(0.3s);
            }
            &.checked {
              .inner {
                opacity: 1;
              }
            }
          }
        }
        .answer-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @media (max-width: 767px) {
            margin-top: 24px;
          }
          .title {
            position: absolute;
            top: 5px;
            font-family: "Caveat", cursive;
            font-style: normal;
            font-size: 30px;
            line-height: 36px;
            text-transform: capitalize;

            @media (max-width: 767px) {
              position: unset;
              font-size: 23px;
              line-height: 24px;
            }
          }
          .negative {
            left: 15px;
          }
          .positive {
            right: 0;
          }
        }
      }
      .button-container {
        display: flex;
        justify-content: center;
        margin: 32px 0 190px;

        @media (max-width: 767px) {
          margin: 24px 0 80px;
        }
        .next-button {
          height: 40px;
          width: 280px;
          background: no-repeat center url("https://soultime.s3-eu-west-1.amazonaws.com/assets/button_full_gradient_40x280.svg");
          background-size: cover;
          border: none;
          opacity: 0.5;
          cursor: unset;
          pointer-events: none;
          @include transition(0.3s);

          &.is-active {
            pointer-events: auto;
            opacity: 1;
            cursor: pointer;
          }
        }
      }
    }
  }
  .well-done {
    display: none;
    border-radius: 0 0 30px 30px;

    .title {
      max-width: 430px;
      margin: 0 auto;
      padding-bottom: 46px;
      text-align: center;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      opacity: 0.7;

      p {
        margin: 0;
      }
      p:first-child {
        margin-bottom: 34px;
      }
    }
    .privacy {
      padding: 58px 0 118px;
    }
  }
  #register-form-modal {
    position: fixed;
    min-height: 100vh;
    height: 100%;
    z-index: 2;
    overflow: auto;

    .forms-container {
      .tab-content {
        height: 100%;
        .submit {
          margin-bottom: 105px;
        }
      }
    }
  }
}

#check-up-results {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding-bottom: 80px;
  }
  .results-slider-container {
    margin: 80px 0;
    width: 278px;

    @media (max-width: 767px) {
      margin: 32px 0;
    }
    .results-slider {
      width: 100%;

      .slick-next, .slick-prev {
        top: 25%;
      }
      .results-slider-item {
        //padding: 0 15px;
        padding: 0 5px;

        img {
          width: 100%;
        }
      }
      .slick-dots {
        bottom: -35px;
        li {
          button:before {
            font-size: 30px;
            color: #fff;
            opacity: 0.5;
          }
        }
        .slick-active {
          button:before {
            font-size: 40px;
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
  }
  .well-done {
    margin-left: 55px;
    max-width: 395px;
    text-align: center;

    @media (max-width: 767px) {
      margin: 35px 0 0;
      padding: 0 15px;
    }
    .results-title {
      font-family: "Nunito Sans", sans-serif;
      font-size: 18px;
      line-height: 30px;
      color: #E9EEF4;

      @media (max-width: 767px) {
        padding: 0 5px;
        line-height: 27px;
      }
    }
    .results-description {
      margin-bottom: 40px;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif !important;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      color: #E9EEF4;

      @media (max-width: 767px) {
        margin: 0;
      }
    }
    .results-links {
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        display: none;
      }
      a, img {
        height: 56px;
      }
      .apple-link {
        width: 187px;
      }
      .google-link {
        width: 193px;
      }
    }
  }
  &.check-up-results-success {
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
    .well-done {
      margin: 120px 0 0;
      max-width: 422px;

      @media (max-width: 767px) {
        margin-top: 40px;
      }
    }
    .results-description {
      margin-bottom: 64px;
    }
    .points-list {
      .point-block {
        font-family: "Montserrat", Arial, sans-serif;
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        color: #E9EEF4;
        position: relative;

        .poin-number-block {
          position: absolute;
          top: 2px;
          left: 0;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: #79CDFE;
          display: inline-block;
          text-align: center;

          .poin-number {
            font-family: "Montserrat", Arial, sans-serif;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            color: #FFFFFF;
            margin: 0 auto;
          }
          .poin-number-3 {
            margin-left: 7.5px;
          }
        }

        span {
          font-family: "Nunito Sans", sans-serif;
          font-size: 18px;
          line-height: 28px;
          font-weight: bold;
          text-align: left;
          color: #E9EEF4;
          margin-left: 35px;
          display: table;
        }
      }
      .point-block.second-point {
        margin-top: 64px;
      }
      .point-phone {
        width: 100%;
        height: 556px;
        display: block;
        margin: 32px 0 64px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .point-phone-1 {
        background-image: url(https://soultime.s3-eu-west-1.amazonaws.com/assets/success_phone_1.png);
      }
      .point-phone-2 {
        background-image: url(https://soultime.s3-eu-west-1.amazonaws.com/assets/success_phone_2.png);
      }
      .point-phone-3 {
        background-image: url(https://soultime.s3-eu-west-1.amazonaws.com/assets/success_phone_3.png);
      }
    }
    .results-links {
      display: flex;
      margin-bottom: 64px;

      @media (max-width: 767px) {
        padding-top: 0;
      }
      .apple-link, .google-link, .apple-link img, .google-link img {

        @media (max-width: 767px) {
          height: 51px;
          width: 165px;
        }
        @media (max-width: 374px) {
          height: 41px;
          width: 138px;
        }
      }
      //a, a img {
      //  height: 41px;
      //
      //}
    }
  }
}
